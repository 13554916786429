.bleed {
  --tw-shadow-colored: 0 0 0 100vmax var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  clip-path: inset(0 -100vmax);

  &-gray{
    --tw-shadow: 0 0 0 100vmax #eaeaea;
  }

  &-alternate{
    --tw-shadow: 0 0 0 100vmax #343434;
  }

}
