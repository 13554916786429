.layout {
  @apply lg:max-w-desktop sm:max-w-full mx-auto leading-6 /* border-2 border-cm */;
}

.layout-gap {
  @apply px-layout-gap lg:px-layout-gap-desktop /* border-2 border-red-500 */;
}

.layout-gap-pages {
  @apply px-layout-gap  /* border-2 border-red-500 */;
}

/* Section container */
.section{
  @apply flex flex-col gap-4 mt-4 p-4
}