.footer {
  @apply layout layout-gap fixed bottom-0 inset-x-0 hidden bg-black text-cm  md:bg-white justify-between p-1 md:p-4 flex-col md:flex-row gap-1 md:gap-4;

  &.show {
    @apply flex ;
  }

  ul {
    @apply gap-4 flex;

    li {
      @apply text-cm md:text-gray4 text-sm font-satoshi text-center;

      a {
        @apply text-cm md:text-gray4;
      }

      &.copy{
        @apply text-xs md:text-sm;
      }

    }
  }

}