.adv-title::before {
  content: 'Pubblicità';
  @apply font-satoshi text-gray2 text-xs my-2.5;
}


.adv-mobile {
  @apply text-center bleed bleed-gray min-h-adv flex items-center justify-start flex-col bg-adv-color py-2 w-full bg-adv bg-adv-position-default bg-no-repeat;
}

.adv-mobile-wide {
  @apply text-center bg-adv bg-no-repeat flex items-center justify-start flex-col bg-adv-color md:hidden w-full bleed bleed-gray min-h-adv-small bg-adv-small bg-adv-position-50-70;
}

.adv-desktop-wide {
  @apply text-center hidden bg-adv bg-no-repeat items-center justify-start flex-col w-full bleed bleed-gray adv-title min-h-60 bg-center bg-adv-color md:flex;
}

.adv-desktop-no-wide {
  @apply text-center hidden bg-adv bg-no-repeat items-center justify-start flex-col w-full bleed-gray adv-title min-h-60 bg-center bg-adv-color md:flex;
}

.adv-desktop-vertical {
  @apply text-center hidden bg-adv bg-no-repeat min-h-adv items-center justify-start flex-col w-full adv-title bg-adv-position-default md:flex;
}


.adv-sticky {
  @apply md:hidden fixed w-full z-50 mb-[5px] bottom-0 flex flex-col items-center;
}