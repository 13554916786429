.headline{
  @apply table bg-yellow-a border border-solid border-cm text-black text-xs font-satoshi mr-auto px-2 rounded font-bold overflow-hidden text-ellipsis whitespace-nowrap md:whitespace-normal
}

.headline-high-impact{
  @apply table bg-yellow-a border border-solid border-cm text-white text-xs font-satoshi mr-auto px-2 rounded  overflow-hidden text-ellipsis whitespace-nowrap md:whitespace-normal

}

.headline-green{
  @apply table bg-cm-2 text-white text-base mr-auto px-2 rounded font-satoshi overflow-hidden text-ellipsis whitespace-nowrap md:whitespace-normal
}

.headline-alternate{
  @apply table bg-color-5 text-cm text-base mr-auto px-2 rounded font-satoshi overflow-hidden text-ellipsis whitespace-nowrap md:whitespace-normal
}